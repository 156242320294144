import { reactNativeUtils, objectUtils } from 'tds-common-fe';
import { getBrowserMainVersion, isSafari } from 'tds-common-fe/src/lib/utils/browserUtils';
import { consumerInfo } from '../api/headerUtils';

const types = {
    initialized: '',
    loading: '',
    error: '',
    openURL: '',
    loginSuccess: '',
    personalProfileUpdated: '',
    companyProfileUpdated: '',
    closeIframe: '',
    accessTokenNotFound: '',
    logout: '',
};

type DataType = keyof typeof types;

export const appDataTypes = objectUtils.populateKeysToValue(types);

type Message = {
    type: DataType;
    data: any;
    dismiss: boolean;
};

export interface WebKit {
    messageHandlers: {
        appHandler?: {
            postMessage: (message: Message) => void;
        };
        eagleIDHandler?: {
            postMessage: (message: Message) => void;
        };
    };
}

export function fromApp(): boolean {
    const { client } = consumerInfo;
    return (
        !!window.webkit?.messageHandlers.eagleIDHandler ||
        !!window.webkit?.messageHandlers.appHandler ||
        reactNativeUtils.fromReactNativeApp() ||
        client === 'ios' ||
        client === 'mobileios'
    );
}

export function postAppData(type: DataType, data: any, dismiss: boolean = false) {
    const handler = window.webkit?.messageHandlers.appHandler || window.webkit?.messageHandlers.eagleIDHandler;
    if (handler) {
        handler.postMessage({
            type,
            data,
            dismiss,
        });
    } else if (reactNativeUtils.fromReactNativeApp()) {
        reactNativeUtils.postReactNativeAppData(type, data);
    }
}

export function showError(errorMessage: string) {
    postAppData(appDataTypes.error, errorMessage);
}

// sample: Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148
export const IOS_REGEX = /OS (\d+)_\d+_\d+ like Mac OS X/;

// temp way to handle log in on ios12/13 TDS-1843
export const isOldIosVersion = () => {
    if (fromApp()) {
        const match = navigator.userAgent.match(IOS_REGEX);
        if (match) {
            return +match[1] <= 13;
        }
        // default to return the newer UI
        return false;
    }
    return isSafari() && (getBrowserMainVersion() ?? 0) <= 13;
};
