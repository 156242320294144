import { routeUtils, browserUtils } from 'tds-common-fe';
import * as appUtils from '../utils/appUtils';
import { intlProxy } from '../localization/IntlProxy';
import * as storageUtils from '../utils/storageUtils';

export interface ClientParams {
    product: string;
    origin: string;
    client: string;
    version: string;
    appVersion: string;
    appClientID: string;
    deviceID: string;
}

export const consumerInfo: Partial<ClientParams> = {};

export const getScreeningEagleHeaders = (): { [key: string]: string } => {
    const { product, client, version, deviceID } = consumerInfo;
    const isWeb = client === 'web' || client === 'mobileweb';
    const isApp = appUtils.fromApp();
    const clientHeader = isApp ? client ?? 'ios' : browserUtils.isMobileBrowser() ? 'mobileweb' : 'web';

    if (isWeb || isApp) {
        return {
            'X-User-Agent': `product=${product}; version=${version}; client=${clientHeader}`,
            'X-Accept-Language': intlProxy.locale,
            'X-SE-Client-Id': deviceID ?? browserUtils.getDeviceID(),
        };
    }

    return {};
};

export const retrieveConsumerInfo = () => {
    const { product, origin, client, version, appVersion, appClientID } = routeUtils.parseQuery<ClientParams>(
        location.search,
        true
    );

    if (origin || product) {
        storageUtils.saveSessionItem('product', origin ?? product ?? '');
    }
    if (client) {
        storageUtils.saveSessionItem('client', client);
    }
    if (version || appVersion) {
        storageUtils.saveSessionItem('version', version ?? appVersion ?? '');
    }
    if (appClientID) {
        consumerInfo.deviceID = appClientID;
        browserUtils.setDeviceIDCookie(appClientID);
    } else {
        consumerInfo.deviceID = browserUtils.getDeviceID();
    }

    consumerInfo.product = origin ?? product ?? storageUtils.getSessionItem('product') ?? 'inspect';
    consumerInfo.client = client ?? storageUtils.getSessionItem('client') ?? '';
    consumerInfo.version = version ?? appVersion ?? storageUtils.getSessionItem('version') ?? '';
};
