import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, message } from 'antd';
import { FormItem, useFormCheckErrors, routeUtils } from 'tds-common-fe';
import styles from './Activation.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import AuthInput from '../../../Authentication/Custom/AuthInput';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import * as userService from '../../../../api/userService';
import { UserLicenseErrorCode } from '../../../../api/userService';
import { APIError } from '../../../../api/error';
import { FieldsType, rules, licenseKeyMaxLength, activatedKeyMessage, invalidKeyMessage } from './activationRules';
import postMessenger, { PopupMessageType } from '../../../../utils/PostMessenger';
import ConfirmModal from '../../../Shared/ConfirmModal';
import { incompleteUUIDRegex } from '../../../../utils/regex';
import { formatUUID } from '../../../../utils/uuidUtils';
import { RootState } from '../../../../reducers';
import { useFormatMessage } from '../../../../localization/useFormatMessage';

const uuidHyphenPositions = [8, 13, 18, 23];

class Item extends FormItem<FieldsType> {}

const Activation = ({ onlyActivation }: { onlyActivation: boolean }) => {
    const { licenseKey: paramLicenseKey } = routeUtils.parseQuery<{ licenseKey: string }>(location.search);
    const userProfile = useSelector((state: RootState) => state.profile.userProfile);
    const { email } = userProfile;

    const { formatMessage } = useFormatMessage();

    const [form] = Form.useForm();
    const { setFieldsValue, validateFields } = form;

    const modalContainer = useRef<HTMLDivElement>(null);
    const inputs = useRef<HTMLInputElement[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [isPopupVisible, setPopupVisible] = useState(false);

    const handleMessage = (messageType: PopupMessageType, messageContent: string) => {
        if (postMessenger.parentOrigin) {
            postMessenger.postPopupMessage(messageType, messageContent);
        } else {
            message[messageType](messageContent);
        }
    };

    const handleSubmit = useCallback(() => {
        setPopupVisible(true);
    }, []);

    const activateLicenseKey = useCallback(async () => {
        if (email) {
            const licenseKey = form.getFieldValue('licenseKey');
            setSubmitting(true);
            try {
                // Call activation API
                await userService.activateUserLicense(licenseKey);
                handleMessage(
                    'success',
                    formatMessage({ id: 'License.Activation.Success' }, { key: licenseKey, email })
                );
                setFieldsValue({ licenseKey: undefined });
            } catch (error) {
                // If license key is invalid or already been activated, fall back and show the error message
                const errorCode = (error as APIError)?.response?.data?.code;
                switch (errorCode) {
                    case UserLicenseErrorCode.LicenseKeyAlreadyInUse:
                        form.setFields([{ name: 'licenseKey', errors: [activatedKeyMessage as any] }]);
                        break;
                    case UserLicenseErrorCode.LicenseKeyInvalid:
                        form.setFields([{ name: 'licenseKey', errors: [invalidKeyMessage as any] }]);
                        break;
                    default:
                        handleMessage('error', formatMessage({ id: 'License.Activation.Failed' }));
                }
            }
        }
        setSubmitting(false);
        setPopupVisible(false);
    }, [email, form, formatMessage, setFieldsValue]);

    const currentKeypress = useRef('');
    const prevLicenseKeyValue = useRef('');

    const onKeyDown = useCallback((event: React.KeyboardEvent) => {
        currentKeypress.current = event.key;
    }, []);

    const onLicenseInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            let newValue = formatUUID(value.trim());

            if (incompleteUUIDRegex.test(newValue)) {
                if (uuidHyphenPositions.includes(newValue.length)) {
                    const pressedKey = String(currentKeypress.current).toLowerCase();
                    if (!(pressedKey === 'backspace' || pressedKey === 'delete')) {
                        newValue = `${newValue}-`;
                    }
                }
            } else {
                newValue = prevLicenseKeyValue.current;
            }

            setFieldsValue({ licenseKey: newValue.toUpperCase() });
            prevLicenseKeyValue.current = newValue;
            if (value !== newValue) {
                validateFields(['licenseKey']);
            }
        },
        [setFieldsValue, validateFields]
    );

    const { checkErrors } = useFormCheckErrors(form, ['licenseKey']);

    return (
        <>
            <div className={styles.inner_frame} style={{ marginTop: onlyActivation ? 0 : 24 }}>
                <Form form={form} layout="vertical" className={styles.form} onFinish={handleSubmit as any}>
                    <h1 className={styles.title}>
                        <FormattedMessage id="License.Field.Title" />
                    </h1>
                    <p className={styles.content}>
                        <FormattedMessage id="License.Field.LicenseKey.description" values={{ count: 32 }} />
                    </p>
                    <div className={styles.input_button_layout}>
                        <Item
                            className={styles.form_item}
                            name="licenseKey"
                            rules={rules.licenseKey}
                            initialValue={paramLicenseKey ? formatUUID(paramLicenseKey).toUpperCase() : ''}
                        >
                            <AuthInput
                                style={{ fontSize: 14 }}
                                placeholder={'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'}
                                inputs={inputs}
                                disabled={submitting}
                                autoComplete="off"
                                onChange={onLicenseInputChange}
                                onKeyDown={onKeyDown}
                                maxLength={licenseKeyMaxLength}
                            />
                        </Item>

                        <Item shouldUpdate>
                            {() => (
                                <AnalyticsButton
                                    type="primary"
                                    htmlType="submit"
                                    className={styles.button_style}
                                    disabled={submitting || checkErrors()}
                                >
                                    <FormattedMessage id="License.Activate" />
                                </AnalyticsButton>
                            )}
                        </Item>
                    </div>
                </Form>
            </div>
            <ConfirmModal
                visible={isPopupVisible}
                onCancel={() => setPopupVisible(false)}
                container={modalContainer.current}
                title={<FormattedMessage id="License.ActivationPopup.Title" />}
                content={
                    <>
                        <FormattedMessage id="License.ActivationPopup.Content" />
                        <b className={styles.email}>{email}</b>
                        <FormattedMessage id="License.ActivationPopup.Action" />
                    </>
                }
                buttons={[
                    { title: <FormattedMessage id="Common.Cancel" />, onClick: () => setPopupVisible(false) },
                    {
                        title: <FormattedMessage id="Common.Confirm" />,
                        type: 'primary',
                        onClick: activateLicenseKey,
                        disabled: submitting,
                    },
                ]}
            />
            <div ref={modalContainer} />
        </>
    );
};

export default Activation;
